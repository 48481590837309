import React, { useEffect, useState } from "react";

import { Slide, Box, Grid } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

import Welcome from "./welcome Screen/Welcome";
import "./App.css";
import ContactButton from "./Components/ContactButton";
import ContactDetails from "./ContactDetails/ContactDetails";
import Footer from "./Components/Footer";
import event from "./utils/Analytics";

const App = () => {
  const [scrollDir, setScrollDir] = useState<"DOWN" | "UP">("UP");
  const [selectedScreen, setSelectedScreen] = useState<
    "WELCOME" | "CONTACTDETAILS"
  >("WELCOME");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    event("new_user");
  }, []);

  useEffect(() => {
    if (location.pathname.includes("ContactDetails")) {
      setSelectedScreen("CONTACTDETAILS");
    } else {
      setSelectedScreen("WELCOME");
    }
  }, [location]);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "DOWN" : "UP");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return (
    <Box>
      <Slide direction="up" in={scrollDir === "UP"} mountOnEnter unmountOnExit>
        <Grid
          sx={{
            zIndex: "modal",
            position: "fixed",
            bottom: 30,
            width: "100%",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <ContactButton
            onPress={() => {
              navigate(
                selectedScreen !== "CONTACTDETAILS" ? "/ContactDetails" : "/"
              );
            }}
            text={
              selectedScreen !== "CONTACTDETAILS" ? "Contact Details" : "Back"
            }
          />
        </Grid>
      </Slide>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={selectedScreen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          {selectedScreen === "WELCOME" ? <Welcome /> : <ContactDetails />}
          <Footer />
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default App;
