import { Typography } from "@mui/material";

type HeadingType = {
  children?: React.ReactNode;
};

const Heading = (props: HeadingType) => {
  return (
    <Typography variant="h4" fontWeight={"bold"} align="left" mt={1} mb={1}>
      {props.children}
    </Typography>
  );
};

export default Heading;
