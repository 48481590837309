import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";

import "@fontsource/roboto/400.css";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  CssBaseline,
} from "@mui/material";

import ProjectViewer from "./Projects/ProjectViewer";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fefefe",
      light: "#ffffff",
    },
    secondary: {
      main: "#ff0000",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),

    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
});

theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/ContactDetails" element={<App />}></Route>
          <Route path="projects">
            <Route path="SignTutor" element={<ProjectViewer />}></Route>
            <Route path="FishFeeder" element={<ProjectViewer />}></Route>
            <Route path="PortraitDrawer" element={<ProjectViewer />}></Route>
            <Route path="TicTacToeBot" element={<ProjectViewer />}></Route>
            <Route path="WarehouseRobot" element={<ProjectViewer />}></Route>
            <Route path="LineFollower" element={<ProjectViewer />}></Route>
            <Route path="PositionControl" element={<ProjectViewer />}></Route>
            <Route path="Aquamesh" element={<ProjectViewer />}></Route>
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
