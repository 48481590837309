import { useRef, useState, useEffect } from "react";
import { Stack, Typography, Button, Grid, Box } from "@mui/material";
import NET from "vanta/dist/vanta.net.min";
import ListItem from "./components/ListItem";
import { saveAs } from "file-saver";

const ContactDetails = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: myRef.current,
          mouseControls: false,
          touchControls: false,
          gyroControls: true,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0xff0000,
          backgroundColor: 0x0,
          points: 13.0,
          maxDistance: 17.0,
          spacing: 20.0,
          showDots: false,
        })
      );
    }
  }, [vantaEffect]);

  return (
    <Stack
      direction="column"
      sx={{
        minHeight: "100vh",
        alignContent: "center",
        justifyContent: "center",
      }}
      ref={myRef}
    >
      <ListItem
        label="LinkedIn Profile"
        icon="linkedIn"
        onPress={() => {
          window.open(
            "https://www.linkedin.com/in/kealym-bromley/",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
      <ListItem
        label="Github Profile"
        icon="github"
        onPress={() => {
          window.open("https://github.com/KealymB");
        }}
      />
      <ListItem
        label="kealymmb@gmail.com"
        icon="email"
        onPress={() => {
          window.open("mailto:kealymmb@gmail.com");
        }}
      />
      <ListItem
        label="Download Resume"
        icon="download"
        onPress={() => {
          saveAs(
            process.env.PUBLIC_URL + "Kealym_Bromley_CV.pdf",
            "Kealym_Bromley_CV"
          );
        }}
      />
      <ListItem
        label="+31 630 685040"
        icon="phone"
        onPress={() => {
          window.open("tel:+31630685040");
        }}
      />
    </Stack>
  );
};

export default ContactDetails;
