import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";

type ProjectCardProps = {
  title: string;
  desc: string;
  image?: string;
  disabled?: boolean;
  onPress: () => void;
};

const ProjectCard = (props: ProjectCardProps) => {
  return (
    <Card
      sx={{
        minWidth: 250,
        margin: 2,
        maxWidth: 300,
        maxHeight: 550,
        minHeight: 350,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      elevation={8}
      variant="outlined"
    >
      <CardContent sx={{ alignItems: "center" }}>
        <Typography
          variant="h5"
          component="div"
          marginBottom={2}
          align={"center"}
        >
          {props.title}
        </Typography>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.image && (
            <img
              src={props.image}
              alt="Logo"
              style={{
                height: 150,
                objectFit: "cover",
                display: "block",
              }}
            />
          )}
        </Box>
        <Typography variant="body2" marginTop={2}>
          {props.desc}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            props.onPress();
          }}
          disabled={props.disabled}
        >
          {props.disabled ? "Coming Soon" : "View Project"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
