import { useState, useCallback } from "react";
import {
  Link,
  Grid,
  useMediaQuery,
  Divider,
  Container,
  Typography,
} from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

const PortraitDrawer = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container
      sx={{
        paddingBottom: 5,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography align="center" variant="h3">
        Coming Soon
      </Typography>
    </Container>
  );
};
export default PortraitDrawer;
