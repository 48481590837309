import { Button, Tooltip, Grid } from "@mui/material";

type ImageType = {
  onPress: () => void;
  image: string;
};

const Image = (props: ImageType) => {
  return (
    <Grid item md={4} sx={{ display: "flex" }} justifyContent="center">
      <Tooltip
        disableFocusListener
        disableTouchListener
        title="Press to view in full screen."
      >
        <Button
          onClick={() => {
            if (props?.onPress) {
              props.onPress();
            }
          }}
          sx={{ marginBottom: 2, alignSelf: "center" }}
        >
          <img
            src={props.image}
            alt="Logo"
            style={{
              height: 200,
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Button>
      </Tooltip>
    </Grid>
  );
};

export default Image;
