import { useRef, useState, useEffect } from "react";
import { Stack, Typography, Grid, Box, Fade, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate, useLocation } from "react-router-dom";
import NET from "vanta/dist/vanta.net.min";
import { BsChevronCompactDown } from "react-icons/bs";

import ProjectCard from "./components/ProjectCard";
import SignTutorLogo from "../images/SignTutor.png";
import AutoFeeder_Front from "../images/AutoFeeder_Front.png";
import Delta_Front from "../images/Delta_Front.jpeg";
import TicTac_Front from "../images/TicTac_Front.png";
import AquameshLogo from "../images/aquamesh_rendered.png";
import WarehouseLogo from "../images/warehouse_overview.png";
import LinefollowerLogo from "../images/linefollower_overview.jpg";
import MotorLogo from "../images/motor_overview.jpeg";

const Welcome = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  const projectsRef = useRef<null | HTMLDivElement>(null);

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: myRef.current,
          mouseControls: false,
          touchControls: false,
          gyroControls: true,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0xff0000,
          backgroundColor: 0x0,
          points: 13.0,
          maxDistance: 17.0,
          spacing: 20.0,
          showDots: false,
        })
      );
    }
  }, [vantaEffect]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Stack direction="column" sx={{ minHeight: "100vh" }} ref={myRef} p={3}>
      <Box
        sx={{
          minHeight: "100vh",
        }}
        p={{ xs: 2, md: 2, xl: 8 }}
      >
        <Typography variant={"h4"} color={"secondary"} fontWeight={"bold"}>
          I am
        </Typography>
        <Typography variant={"h1"} fontWeight={900}>
          Kealym Bromley
        </Typography>
        <Typography variant={"h4"} color={grey[300]} mt={2} fontWeight={"bold"}>
          a 2022 Mechatronics Engineering graduate.
        </Typography>
        <Typography variant={"h5"} mt={5}>
          🤖 Robotics enthusiast
        </Typography>
        <Typography variant={"h5"} mt={2}>
          🎸 Guitar player
        </Typography>
        <Typography variant={"h5"} mt={2}>
          🧗🏼 Rock climber
        </Typography>
        <Typography variant={"h5"} mt={2}>
          📖 Driven by my thirst for knowledge
        </Typography>
        <Fade in={true} style={{ transitionDelay: `5000ms` }} timeout={1000}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            mt={{ xs: 2, md: 5, lg: 10, xl: 20 }}
          >
            <Button
              onClick={() => {
                if (projectsRef?.current) {
                  projectsRef.current.scrollIntoView({ behavior: "smooth" });
                }
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant={"h4"}>Scroll Down</Typography>
              <BsChevronCompactDown
                size={100}
                style={{ marginTop: -30, marginBottom: -30 }}
              />
            </Button>
          </Box>
        </Fade>
      </Box>

      <Box justifyContent="center" mt={2} ref={projectsRef}>
        <Typography variant={"h4"} fontWeight={"bold"} ml={{ xs: 0, xl: 2 }}>
          My projects
        </Typography>
        <Grid container direction="row" justifyContent="center">
          <ProjectCard
            title="AI Powered ASL Tutor"
            desc="A proof-of-concept AI sign language teaching app. SignTutor teaches and tests 5 static American Sign Language letters using AI to provide feedback to the sign's correctness. "
            onPress={() => {
              navigate("/projects/SignTutor");
            }}
            image={SignTutorLogo}
          />
          <ProjectCard
            title="Auto Fish Feeder"
            desc="A fish feeder built to handle South African power cuts, includes an intuitive interface to set feed days, times and portion size. Utilizing a battery powered RTC to keep time while not powered and saving all settings to EEPROM to avoid data loss."
            onPress={() => {
              navigate("/projects/FishFeeder");
            }}
            image={AutoFeeder_Front}
          />
          <ProjectCard
            title="Delta Robotic Artist"
            desc="My thesis project is to design and build a delta robot that can, using AI, take a picture of a user and convert that image into a cartoon version. This 'cartoonified' portrait is then sent through a path finding algorithm and drawn by the inverted Delta robot."
            onPress={() => {
              navigate("/projects/PortraitDrawer");
            }}
            image={Delta_Front}
            disabled
          />
          <ProjectCard
            title="Remote Tic Tac Toe"
            desc="A player is able to draw their circle or cross on a piece of paper and using image recognition will show their move on the opponents app. When the opponent makes a move on the app the robot draws in that move on the physical piece of paper."
            onPress={() => {
              navigate("/projects/TicTacToeBot");
            }}
            image={TicTac_Front}
          />
        </Grid>
      </Box>
      <Box justifyContent="center" mt={2}>
        <Typography variant={"h4"} fontWeight={"bold"} ml={{ xs: 0, xl: 2 }}>
          University Projects
        </Typography>
        <Grid container direction="row" justifyContent="center">
          <ProjectCard
            title="Full State Feedback Warehouse Robot"
            desc="The task was to create a factory-robot controller that could pick up a package, adjust motor torques' for the package’s unknown weight and then allow an operator to set the position of the package."
            onPress={() => {
              navigate("/projects/WarehouseRobot");
            }}
            image={WarehouseLogo}
          />
          <ProjectCard
            title="Line following maze solver"
            desc="The autonomous robot, equipped with 4 line sensors and wheel encoders needed to navigate and solve a maze. Furthermore, the robot must be able to find the optimal path and then race the maze to complete it in as fast a time as possible."
            onPress={() => {
              navigate("/projects/LineFollower");
            }}
            image={LinefollowerLogo}
          />
          <ProjectCard
            title="Motor Position Control"
            desc="Using ladder logic to program the PLC, C# for the control algorithm and DOPsoft for the HMI, a control algorithm was developed to control the position of a motor. Allowing the operator to set the position using the HMI."
            onPress={() => {
              navigate("/projects/PositionControl");
            }}
            image={MotorLogo}
          />

          <ProjectCard
            title="Aquamesh - smart water controller"
            desc="Following requirements gathered in an interview with a small scale farmer about their water problems. My team's solution was an array of smart inter-connected devices that work together to water many acres of crops efficiently."
            onPress={() => {
              navigate("/projects/Aquamesh");
            }}
            image={AquameshLogo}
          />
        </Grid>
      </Box>
    </Stack>
  );
};

export default Welcome;
