import { Typography, Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 80,
        alignContent: "Center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        borderTop: 1,
        borderColor: "gray",
        display: "flex",
      }}
    >
      <Typography align="center">
        Built by Kealym Bromley using React, MUI and VantaJS. Hosted on Netlify.
      </Typography>
    </Box>
  );
};
export default Footer;
