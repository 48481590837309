import { useState, useCallback } from "react";
import { Link, Grid, useMediaQuery, Divider, Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

import FoodPortioner from "./media/feeder_blades.png";
import FeederHousing from "./media/exploded_view.png";
import BreadBoard from "./media/breadboard.jpeg";
import PerfBoard from "./media/perfboard.jpeg";
import Demonstration from "./media/demonstration.mp4";

const FishFeeder = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [FoodPortioner, FeederHousing, BreadBoard, PerfBoard];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            This fish auto feeder was built with the objective to precisely
            portion fish food, without clogging. Setting the date and time must
            be intuitive for new users. The product must also allow the user to
            select which days, times and quantity of food to feed the fish. The
            product needed to be resilient to the common South African power
            cuts and must be an all in one unit.
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={Demonstration} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Hardware Design</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            To meet the required features, the first step was to design a food
            portioning system that wouldn't clog with differing sized fish
            pellets. I decided to design my own food portioning system which can
            be seen in the figure {sm ? "to the right." : "below."} There are
            several design choices that needed to be made when deciding on the
            portioning system. The first was how would it be controlled. I ended
            up deciding to use a stepper motor as they have precision and the
            ability to sense if they are stalled. I also installed a hole for a
            magnet so that, using a hall effect sensor, I could home the motor
            each time the power is cut.
          </Body>
        </Grid>
        <Image image={FoodPortioner} onPress={() => openImageViewer(0)} />
      </Grid>
      <SubHeading>Feeder housing</SubHeading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The next step was designing the housing. After choosing an
            industrial style for the housing there were several limitations that
            I needed to consider. One of these limitations was that my 3D
            printer only allows for a 22cm x 22cm x 30cm build volume. Using
            SolidWorks I got to designing the assembly, making sure to design in
            tolerances for fasteners as well as any mating components. The
            exploded view of the full assembly can be seen in the figure{" "}
            {sm ? "to the right." : "below."}
          </Body>
        </Grid>

        <Image image={FeederHousing} onPress={() => openImageViewer(1)} />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Electronics</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            Initially all electronics were built on a breadboard for easy
            testing and rapid prototyping. During this phase of the project I
            chose the equipment that will be used. To power the device I using a
            12V, 2A barrel jack power brick. To drive the stepper motor silently
            I decided to use the Bigtree tmc2208. I initially used the ESP32 as
            the dev board, but as it is overpowered for this application I later
            switched to the arduino nano; this ended up causing me to run out of
            memory to draw the interface, the solution I will talk about in the
            programming section. Next, I used a 128x64px monochrome screen as I
            had it on hand as well as a buck boost converter to power the
            Arduino nano with 5V. Finally, I chose the DS3231 rtc to keep time.
          </Body>
        </Grid>
        <Image image={BreadBoard} onPress={() => openImageViewer(2)} />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Programing</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            As programming isn't interesting to look at I have a image of the
            inner housing and electronics. There were several challenges while
            coding the control logic, the first was moving the motor while
            updating the interface. I overcame this by first rendering the
            screen and then controlling the stepper motor. The next issue was
            that the graphics library wrote too much to memory, after a lot of
            research I found out about pagination. This allows the graphics
            library to only load a quarter of the screen into memory at a time
            and then draw each quarter over 4 clock cycles. This effectively
            divided the memory used by 4 fixing my memory overflow issue. The
            last issue was to figure out how to save and retrieve settings so
            that information is not lost during power cuts, for this I ended up
            using Arduino's EEPROM library. For the user interface I used a
            state system and designed the interface using Figma as this gave me
            the pixel positions for each interface item, allowing for easy
            transfer into code. This program can be found at{" "}
            <Link
              href="https://github.com/KealymB/FeederControlLogic"
              rel="noopener noreferrer"
              target="_blank"
            >
              this repo
            </Link>
            .
          </Body>
        </Grid>
        <Image image={PerfBoard} onPress={() => openImageViewer(3)} />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>The conclusion</Heading>
      <Body>
        Overall I believe the project was a success. No settings are lost during
        power cuts, the unit is robust and feeds reliably. The interface is easy
        to use and very intuitive.
      </Body>
      <SubHeading>Improvements</SubHeading>
      <Body>
        • Assembly of the unit was difficult due to space constraints, so I
        would either improve the housing design to factor in extra space for
        assembly, or choose better cabling so connecting the electronics to the
        housing sections is easier.{"\n"}• The multiple reads and writes to
        EEPROM could degrade the lifespan of the product, if I had more time I
        would have limited the number of writes.{"\n"}• The feeder blades do not
        interface well with the funnel that directs food out of the port, which
        would have been nice to fix with a more elegant funnel.
      </Body>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default FishFeeder;
