import { useState, useCallback } from "react";
import { Grid, useMediaQuery, Divider, Container, Link } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import { saveAs } from "file-saver";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

import StressAnalysis from "./media/stress_analysis.png";
import ExplodedView from "./media/exploded_view.png";
import RenderedView from "./media/open_view.png";

const Aquamesh = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [RenderedView, ExplodedView, StressAnalysis];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            After interviewing Darren, a tech savvy small-scale farmer, we found
            out that he wanted to grow his farm but scaling his existing
            wasteful water infrastructure would be too expensive. We then
            identified our problem-statement to be that Darren needed a
            controlled way of efficiently watering many acres of crop. After
            communicating a set of proposed solutions to Darren we later found
            out that there were further requirements: the solution to be
            implemented must be rugged to handle farmland, must run without
            cabling or external power and must be able to be controlled
            remotely. {"\n\n"}With these requirements in mind our solution is
            the Aquamesh, an array of smart inter-connected devices that work
            together to water many acres of crops efficiently and automatically.
            Built to last and powered by solar, the Aquamesh uses a wide array
            of ground-based sensors to maximize crop yield by precisely
            controlling watering schedules. I was in charge of submodule 4, the
            enclosure for the Aquamesh. A render of the housing done in Blender
            can be seen {sm ? " to the right." : " below."}
          </Body>
        </Grid>
        <Image image={RenderedView} onPress={() => openImageViewer(0)} />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Design</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            This subsection encapsulates the material-design, size, look and
            shape of the sensor and water controller housing. The overall goal
            of this subsection is to create a safe and easily accessible
            environment for the electromechanical systems. There are two
            housings that need to be designed, one for the water controller,
            that control the mains valves as well as communicate to sensors as
            well as a housing for the sensors, which communicate wirelessly to
            the water controllers and are placed underground. The water
            controller housing needs to be water resistant, knock resistant,
            protected from the sun, and must allow for signals to pass through.
            There must be mounting points to common water mains and must align
            the solar panel at an optimal angle. The sensor housing must be
            waterproof, must allow signals to pass through and must allow for
            the sensors to touch the soil. A common requirement for both
            housings is that the internals must be easily accessible. An
            exploded view of the housing's assembly can be seen{" "}
            {sm ? "to the right." : "below."}
          </Body>
        </Grid>
        <Image image={ExplodedView} onPress={() => openImageViewer(1)} />
      </Grid>
      <SubHeading>Design Choices</SubHeading>
      <Body>
        • Material - The two most common plastics used in housings is Polyvinyl
        Chloride (PVC) and Polypropylene (PP). PVC is very strong, with added
        chemicals has great resistance to UV degradation and is very stiff,
        which is important, and the housing needs to be watertight, and if the
        material gives, it could allow water to penetrate the seals.
        Unfortunately, PVC is very brittle, with small falls causing cracks,
        which could lead to a compromise in water proofing. PP is a fantastic
        material that is easy to manufacture with great material properties such
        as high strength and stiffness while not being brittle. Unfortunately,
        PP is very susceptible to UV light and while it works fantastically
        underground, it would violate the user requirement to have these water
        controllers below ground as well. Fiberglass on the other hand is UV
        resistant, is stiff and although brittle, will only break at much higher
        than a two-meter fall. Added benefits above the user requirements is
        that fiberglass can be easily repaired with matting and resin which is a
        common material for farmers to have on hand. Fiberglass will be the
        chosen material moving forward as it meets all user requirements and has
        added benefits.{"\n\n"}• Internal mount - The user requirement requires
        all the internal components to be mounted securely. Keeping the
        internals in place is important electrically as well as structurally, as
        having the boards and batteries loose in the housing could cause shorts
        as well as could cause damage to the boards when the housing is being
        moved. In the analysis of the user requirements it was brought up that
        the electronics must also be able to be dismounted. The solution is a
        cheese board mount, where many holes are provided at equal spaces and
        sizes to mount electronics and any other internals.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Analysis</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            During analysis of this solution there were several limitations
            identified. {"\n\n"} • Size constraint - As the housing will contain
            a screw on lid the internals will need to fit through wat ever hole
            the lid attaches to. The inner diameter of the access port is 70mm
            with an internal housing length of 165mm. 70mm x 165mm is therefore
            the absolute maximum size of component the housing can accept. This
            is further reduced by the fact that the components are mounted onto
            a cheeseboard which slides through the access port, this reduces the
            70mm height restriction to a maximum component height of 32.5mm. As
            most components are on printed PCBs this restriction should not be
            too limiting.{"\n\n"} • Another consideration would be how deep the
            sensor housing can be placed. Apart from the signal attenuation that
            will occur at considerable depths, we need to make sure the housing
            will not fail first. To make sure the housing can handle the weight
            of the soil above it a stress analysis was done. The stress analysis
            software needed to know the pressure of the soil pushing on it. To
            do this I chose the worst-case density of soil, wet soil. The
            density of wet soil is between 1360 − 1470kg ∙ m−3 , of which I
            chose a middle value of 1400kg ∙ m−3 . Then using this equation:
            𝑃𝑟𝑒𝑠𝑠𝑢𝑟𝑒 = 𝜌𝑤𝑒𝑡𝑠𝑜𝑖𝑙 ∙ 𝑑𝑒𝑝𝑡ℎ ∙ 𝑔𝑟𝑎𝑣𝑖𝑡𝑦 I calculated 3 different
            pressures at a depth of 1m, 2.5m and 5m.At a depth of 1 meter there
            is less than a 0.2mm displacement, which is well within the
            structural limits of fiberglass. The housing had a maximum
            displacement of 0.55mm at a depth of 2.5m, which is still in the
            operating range of fiberglass. At 5m the housing stress is more than
            the maximum stress of fiberglass and would cause failure on the
            housing at this depth. A stress analysis at 2m can be seen
            {sm ? " to the right." : " below."}
          </Body>
        </Grid>
        <Image image={StressAnalysis} onPress={() => openImageViewer(2)} />
      </Grid>

      <Divider sx={{ marginTop: 2 }} />
      <Heading>Conclusion</Heading>
      <Body>
        I think the material, shape, internals and mounts were designed well.
        Although the internal dimensions were a bit tight and the surface area
        for a solar panel is a a bit small.
        <SubHeading>Links</SubHeading>•{" "}
        <Link
          onClick={() => {
            saveAs(
              process.env.PUBLIC_URL + "Aquamesh_Report.pdf",
              "Kealym Bromley - Aquamesh Report"
            );
          }}
        >
          Aquamesh report
        </Link>
      </Body>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default Aquamesh;
