import { Typography, Grid } from "@mui/material";

type SubHeadingType = {
  children: React.ReactNode;
};
const SubHeading = (props: SubHeadingType) => {
  return (
    <Typography
      align="center"
      variant="h5"
      mb={1}
      sx={{ textDecoration: "underline" }}
    >
      {props.children}
    </Typography>
  );
};

export default SubHeading;
