import { useState, useCallback } from "react";
import { Grid, useMediaQuery, Divider, Container, Link } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import { saveAs } from "file-saver";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

import Demo from "./media/Demo.mp4";
import Diagram from "./media/diaagram.png";

const WarehouseRobot = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [Diagram];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The task was to create a factory-robot controller that could pick up
            a package, adjusted itself for the package’s unknown weight and then
            allowed an operator to set the position of the box. The
            factory-robot had two links, with angle sensors at each of the two
            joints as well as a horizontal position sensor, which could replay
            the x position of the robot. There were three motors that could be
            driven using torques, t1, t2 and f1, the t1 and t2 torques affected
            the link angles and the f1 motor force affected the x position of
            the robot.
            {"\n\n"}To model the robot dynamics the equations of motions for the
            robot were derived using Lagrangian mechanics and the robot
            specifications. Using the equations of motion, the frictional forces
            associated with the horizontal movement and link rotation was
            calculated. In milestone 2, feedback-linearization was used to
            linearize the plant so that an appropriate controller could be
            designed. This controller allowed an operator to set the x and z
            position of the end effector, while always keeping link 2 upright.
            The effectiveness of the controller was evaluated using the cost
            function that summed the torques of the motor over time. A demo of
            the simulated robot can be seen {sm ? " to the right." : " below."}
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={Demo} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Theory</Heading>
      <Body>
        There were several theory techniques used in the design of the final
        controller. These techniques included Lagrangian mechanics which was
        utilized to form the equations of motions and the manipulator equation.
        The next is feedback-linearisation which was used to make creating a
        controller easier by allowing linear design techniques. The final theory
        is state estimation, which includes the Kalman filter. {"\n\n"}
        Lagrangian mechanics is used as it simplifies the non-linear Newton
        equations of motion of systems by looking at energies in the system. We
        utilized the manipulator equation.{"\n\n"}Feedback linearisation creates
        a non-linear mapping for the non-linear dynamics, where its output will
        go into the non-linear dynamics. This creates a pseudo input “v” of
        which has a linear relationship with the output. This effectively turns
        the whole plant into a double integrator.{"\n\n"}State estimation was
        used to calculate the package mass. If the plant dynamics are known one
        can solve the manipulator dynamics for the mass of the box, supplying
        the current state of the robot. This state estimation is very noisy and
        so a Kalman filter can be used to filter out the noise. In simple terms
        a Kalman filter blends two imprecise sources of data into a more precise
        result. Unfortunately, my model could not implement the Kalman filter
        due to time constraints. This is where I used a simple sliding average
        to filter out the noise. A sliding window average, averages values over
        a set sample range. This is effectively a low pass filter as high
        frequency noise is removed.
      </Body>

      <Divider sx={{ marginTop: 2 }} />
      <Heading>System ID</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The fist step towards developing a controller is understanding the
            system. A labeled diagram of the system can be viewed in the image{" "}
            {sm ? "to the right.\n\n" : "below.\n\n"}
            All masses and lengths were given, the only parameter not given was
            the drag coefficient. To solve for the drag coefficient the robot
            was simulated, with no actuation, meaning t1, t2 and F1 were all
            zero. This resulted in the two-link robot flopping down due to its
            own weight. The dynamics of generalised coordinates were recorded
            during the simulation and at a time where both dth1 and dth2 were
            non-zero the values for q, dq and ddq were substituted into the
            manipulator equation. This yielded a damping factor of 0.7765. A
            time where dth1 and dth2 were non-zero was picked as if dth1 is zero
            there would be a divide by zero error and if dth2 was zero it would
            cancel out important terms that define the damping factor.
            Practically speaking you cannot measure the frictional force without
            a velocity, that is why dth1 and dth2 needed to be non-zero.{
              "\n\n"
            }{" "}
            For the equations of motion to be formed, the generalized
            coordinates, q, were first decided as [th1, th2, x]T . The mass
            positions and velocities were then calculated in frame 0 and can be
            seen in the report linked at the end of this page.
          </Body>
        </Grid>
        <Image image={Diagram} onPress={() => openImageViewer(0)} />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Design</Heading>
      <Body>
        Now that the system dynamics have been modelled and a drag factor has
        been found the next step is designing a controller for the system.
        Unfortunately, the system is non-linear, and we need it linear to design
        a controller. Therefore, first step is linearising the plant, there
        after a controller can be made that tracks a setpoint and then finally,
        develop a package-mass estimator to allow the controller to pick up
        packages of unknown weight.
      </Body>
      <SubHeading>Feedback linearisation</SubHeading>
      <Body>
        The first step to feedback linearisation is finding an input-output
        mapping, but the manipulator equation does not control the end
        effector’s position. To find this mapping the end effector position was
        first found. {"\n\n"}For feedback linearisation, the outputs need to be
        included in the inputs. The outputs in this case are the torques applied
        to the motors, which cause an acceleration ddq. The end effector matrix
        does not include the acceleration terms, so one needs to differentiate
        end effector matrix till the accelerations appear. I ended up having to
        differentiate the end effector position matrix twice. The code for this
        non-linear mapping can be found in the appendix of the attached report.
      </Body>
      <SubHeading>Controller Design</SubHeading>
      <Body>
        As discussed in the theory section, under Feedback linearisation, the
        plant basically becomes a double integrator. A PD controller was chosen
        to control this system as it is easy to design and implement. In order
        to implement a controller in full-state feedback the appropriate gain
        matrices need to be generated. The first step to generating these
        matrices is creating the state-space model of the double integrator,
        then using the pole positions of the controller as well as the place
        command the gains can be generated. The pole positions were found after
        designing a controller using SISO tool.
      </Body>
      <SubHeading>Mass Estimator</SubHeading>
      <Body>
        The estimator takes in a low-passed q, dq, ddq and tau as well as taking
        in the mode and contact signals. The data coming into the block was
        noisy so a moving average with a window length of 4 samples was added.
        The output, package mass, was delayed by one sample and average with a
        window length of 6. The delay on the output was to break the algebraic
        loop formed between the non-linear controller which is connected back to
        the mass estimator.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Conclusion</Heading>
      <Body>
        In closing, a two-link non-linear warehouse robot can be controlled to
        pickup and move packages of unknown mass, using feedback-linearisation,
        a PD controller, and a state estimator. Although the solution spoke
        about in this report meets the required specifications of moving to the
        package, attaching, and picking up the package of unknown mass and
        keeping it upright to within 30 degrees, there are some pitfalls to the
        approached taken. As talked about in the results section, the poor
        actuation of the torques could have been solved by optimizing the PD
        controller further, or by using LQR optimization. The mass estimation
        did well but could have had a better response time and smaller deviation
        if a Kalman filter were to have been used instead of a moving average.
        <SubHeading>Links</SubHeading>•{" "}
        <Link
          onClick={() => {
            saveAs(
              process.env.PUBLIC_URL + "WarehouseRobot_Report.pdf",
              "Kealym Bromley - Warehouse robot Report"
            );
          }}
        >
          Warehouse robot report
        </Link>
      </Body>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default WarehouseRobot;
