import { useState, useCallback } from "react";
import { Grid, useMediaQuery, Divider, Container, Link } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import { saveAs } from "file-saver";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

import HMIDemo from "./media/PositionControl.mp4";
import Regions from "./media/LowInertia_Regions.png";
import Controller from "./media/controller_architecture.png";
import Results from "./media/results.png";

const PositionControl = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [Regions, Controller, Results];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The overall goal of this practical is to be able to control the
            position of a motor matching or exceeding the requirements laid out
            in the lab. The motor that we are trying to control can have a
            differing inertia and can have an eddy-current break applied. The
            voltage applied to the motor, while in open-loop without a
            controller, merely affects the velocity of the motor. {"\n\n"}There
            were several steps that my partner and I took to developing a
            working controller. The first step was to take the three different
            plant-case transfer functions and simulate different controller
            architectures to find one that best controls the motor position,
            meeting the requirements. We then tested each controller
            architecture on the physical plant in order to evaluate which
            controller type will be best to further design. After picking the
            best working controller we then used SISO-tool as well as the QFT
            toolbox to formally design the controller gains. Using the
            controller gains calculated in SISO tool and QFT toolbox, we went
            back into the simulation to see our expected physical plant output.
            Finally, we implemented the final controller on the physical plant
            using DOPsoft to program the HMI, ladder logic for the PLC and C#
            for the controller. The final system allowed the position to be
            driven by a HMI connected to a PLC. After some tweaks to the code,
            we measured the response of the plant making sure it met all laid
            out requirements. The video
            {sm ? "to the right " : "below "}shows how the motor position tracks
            the commanded position set by the HMI.
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={HMIDemo} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>System Identification</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The first step to identifying the system was to learn the equipment
            in the plant. We found that the voltage into the motor only
            controlled the motors velocity. Next we ramped this voltage and
            measured the output velocity of the motor. This helped us identify
            the operating, dead-zone and saturation regions. This chart can be
            seen in the image
            {sm ? " to the right.\n\n" : " below.\n\n"}We did this for all 3
            plant cases (Low Inertia, High Inertia and Breaked). Next using
            MATLAB's system identification toolbox we approximated the 3 plant
            cases to a first order system and using that got 3 transfer
            functions. To make sure these transfer functions are accurate we ran
            a simulink simulation and ran the recorded input supplied to the
            motor through the transfer functions and compared the recorded data
            to the simulated results.
          </Body>
        </Grid>
        <Image
          image={Regions}
          onPress={() => {
            openImageViewer(0);
          }}
        />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Controller design</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The next step to developing a full solution to control the motor's
            position was to design a controller. We first designed 4 different
            controllers: Lead lag, Proportional, velocity feedback as well as
            PID; making sure that they met the response requirements for all 3
            plant cases. I designed these controllers using SISO tool and after
            simulating them with the plant's transfer function we decided the
            PID would be the best controller architecture as it is easy to
            understand and adjust values on the real plant if needed. The PID
            architecture we chose can be seen in the image
            {sm ? " to the right." : " below."}
          </Body>
        </Grid>
        <Image
          image={Controller}
          onPress={() => {
            openImageViewer(1);
          }}
        />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Implementation</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            Implementing the controller meant connecting the HMI to the PLC and
            then coding the controller in C# and connecting that to the PLC. The
            code for the controller can be found in the report on the
            development of the controller which can be found in the link section
            of this page. After the controller was coded we then connected the
            PLC to the HMI by coding in ladder logic. We made four connections,
            the command from the HMI, the feedback from the position sensor and
            two buttons that enable and disable the motor. The HMI we designed
            was very rushed, but can be seen in the video above. Finally the
            plant was tested and the results can be seen in the image
            {sm ? " to the right." : " below."}
          </Body>
        </Grid>
        <Image
          image={Results}
          onPress={() => {
            openImageViewer(2);
          }}
        />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Conclusion</Heading>
      <Body>
        The controller worked amazingly, it was one of the classes fastest
        reacting controller, with very little overshoot. All requirements were
        met and exceeded, while having fantastic output disturbance rejection.
        The PID controller had the downfall of having no anti-windup, but that
        was coded into the final controller as the integral term created quite a
        bit of overshoot. The PLC and HMI worked very well, but I would have
        liked to better design the HMI if I had more time.
        <SubHeading>Links</SubHeading>•{" "}
        <Link
          onClick={() => {
            saveAs(
              process.env.PUBLIC_URL + "PositionControl_SysID_Report.pdf",
              "Kealym Bromley - System ID Report"
            );
          }}
        >
          Report on the system identification
        </Link>
        {"\n"}•{" "}
        <Link
          onClick={() => {
            saveAs(
              process.env.PUBLIC_URL + "PositionControl_Controller_Report.pdf",
              "Kealym Bromley - Positional Controller Report"
            );
          }}
        >
          Report on the development of the controller
        </Link>
      </Body>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default PositionControl;
