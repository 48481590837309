import { useState, useCallback } from "react";
import { Grid, useMediaQuery, Divider, Container, Link } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import { saveAs } from "file-saver";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

import FollowerDemo from "./media/FollowerDemo.mp4";
import Hardware from "./media/hardware.png";

const LineFollower = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [Hardware];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The project required that Motion control, Line sensing as well as a
            Maze Solver and Shortest Pathfinder algorithm be designed and
            implemented on a two wheel drive robot. The autonomous robot,
            equipped with 4 line sensors and wheel encoders needed to navigate
            and solve a maze. Furthermore, the robot must be able to find the
            optimal path and then race the maze to complete it in as fast a time
            as possible. {"\n\n"}Additionally, the designing and implementation
            of requirements of the robot were completed within Matlab and
            Simulink. Simulink enabled these designs to be simulated and tested
            before running the algorithms on hardware. Additionally, the
            transfer from simulation to hardware within Simulink is simple as
            the base code would remain unchanged. Once the designs were tested
            in simulation, the files were then minimally altered to be able to
            run on the actual hardware on the robot. These files were then also
            saved separately so as to not overwrite the simulation files in
            order to use them as a base. {"\n\n"}The support package for Arduino
            was utilized in order to model the system as well as allow for the
            connection and communication to the Romeo V2 board from the computer
            running Matlab and Simulink. This also allowed for the interfacing
            between Simulink system blocks and the robot. Unfortunately, not a
            lot of footage of this project survived, but{" "}
            {sm ? " to the right " : " below "} is a demo video I made when I
            got the line following working.
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={FollowerDemo} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Hardware description</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The hardware used was the two wheel drive Turtle kit. The kit
            included: RoMeo V2 controller, 2 DC geared motors and 4 IR line
            sensors along with all supporting equipment such as batteries. The
            physical dimensions of the robot are shown{" "}
            {sm ? "to the right." : "below."}With axle length (L) 13.6cm, the
            wheel radius (r) of 3.13cm. The offset for each IR line sensor from
            the center of the robot is: Left outer - (x: 6.43cm, y: 3.54cm),
            Left inner - (x: 6.75cm, y: 0.5cm), Right inner - (x: 6.75cm, y:
            -0.5cm) and Right outer - (x: 6.43cm, y: -3.54cm). The robot had one
            more, unpowered, stabilizing wheel at the back center of the robot.
            Each DC motor had an attached 20 tooth encoder. The RoMeo V2
            controller also contained 5 push buttons that could be read from and
            an attached LED.
            {"\n\n"}The DC motors have both velocity and direction control,
            where the velocity for the left and right motor is controlled via
            sending a PWM signal to pin 5 and 6 respectively. To move in a
            forward direction a digital signal of 0 is sent to pin 4 for the
            left and 7 for the right motor, this is the default direction if no
            signal is sent to these pins. To drive these motors backwards a
            digital 1 is supplied. The PWM signal is not too important as this
            was derived from the wheel speed, although it is important to note
            that it accepts a range from between -127 and 127.
            {"\n\n"}The IR line sensors were connected to digital pins 8, 9, 10
            and 11. They are read as a binary 1 if not on the line and a 0 if
            off the line. They have an internal threshold which dictates what
            LUX value will return a 1 or 0. Although simple, their positioning
            on the robot was of utmost importance. The inner sensors were placed
            1cm apart, this is 0.8cm smaller than the width of the line that the
            robot was required to follow.
            {"\n\n"}The push buttons on the robot were connected to one analog
            input. Pushing a button would change the ADC reading on the analog
            input. Knowing what ADC value corresponds to each button allows us
            to sense when a specific button is being pressed. The ADC reading
            for S1 was 0 and S2 was 163. The LED was connected to digital pin
            13. To turn the LED on a 1 is written to pin 13 and 0 for the LED to
            turn off.
          </Body>
        </Grid>
        <Image
          image={Hardware}
          onPress={() => {
            openImageViewer(0);
          }}
        />
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Motion control design</Heading>
      <Body>
        One of the first problems tackled on the 2WD robot was to be able to
        accurately control both it’s distance and angle. To do this precisely a
        feedback loop is needed, where the input is a desired distance or angle
        and the output is the robot moving a given distance or angle. In order
        to incorporate feedback there needed to be a way to accurately measure
        the robots current distance and angle. To do this, the encoders on each
        of the motors were used. The distance that each wheel traveled was
        calculated by using the cumulative number of ticks that each motor
        encoder counted{"\n\n"}
        Using a discrete PID controller, where the error is calculated by
        minusing the distance traveled from the required distance, the robot can
        move a specified distance. The PID settings were tuned using the
        simulations and then later refined via testing on the hardware. The
        gains that worked best on our hardware were: P = 10, I = 0.05, D = 0.02
        and the filter coefficient of 100. There was also an upper and lower
        limit of the velocity of 0.02 and 0 respectively as the robot didn’t
        need to move backwards.
        {"\n\n"}For longer distances the velocity PID method did not work well
        enough as the distance it used for the error calculation was the average
        between the two wheels and if the wheels did not move at the same rate
        the robot could move at an angle. The solution we initially used was to
        use another PID controller to control the angular velocity of the
        motors. Where the error term was the difference in ticks between the two
        encoders. However, the robot would only need to move a controlled
        distance of around 6cm, which was not far enough for this angle error to
        have a large effect. So as to lighten the load on the computations, we
        removed any angle correction.
        {"\n\n"}The distance control algorithm worked well if there was
        sufficient polling to the robot (this polling was not very stable due to
        MATLAB and Simulink slow computation time). The robot moved the 6cm,
        having a max distance error of 1.5cm and max angle error of 10°.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Line sensing design</Heading>
      <Body>
        Line sensing was one of the more challenging systems to design. There
        were 2 requirements of this system. It needed to relay information so
        that the robot would stay on the line and it needed to determine what
        junction it was on. These two requirements are quite separate and so
        will be looked at in two sections.
        <SubHeading>Line Follow</SubHeading>
        The first step was implementing the 4 digital reads for the 4 line
        sensors, where the two inner ones were used to keep the robot on the
        line. The left inner and right inner sensor was required to stay within
        the 1.8cm line. To do this two approaches were taken. The first approach
        was to have a velocity set where the robot would move forward and if
        either of the inner line sensors were off the line the robot would stop,
        turn a small degree till both sensors were on the line and then continue
        moving forward. This approach led to very jerky motion and unreliable
        results.
        {"\n\n"}The next solution was to use a PID controller and a constant
        forward velocity of 0.05. The error term for the PID controller was
        created using a Stateflow Chart.{"\n\n"}This chart takes in the left and
        right inner sensor and returns a positive w value of 3 if the robot
        needs to turn right and negative 3 if the robot needs to turn left.
        These values go into a PD controller with the following settings: P gain
        0.102, a D gain of 6x10 -4 and a filter coefficient of 12. The output
        saturation was not too important as the robot would only need to turn a
        small amount so a value of ±0.55 was used. Out of the PD controller was
        an angular velocity which was sent to the motors.
        {"\n\n"}This method of line following worked very well, although if the
        computer controlling the robot lagged, the chart could get stuck in the
        TurnRight or TurnLeft states, causing the robot to turn in a circle.
        This didn't happen often so an alternative to this chart was not found.
        The final line following system was placed in a separate chart where it
        can be turned on and off depending on what state the robot is in
        <SubHeading>Junction Detection</SubHeading>
        This was the most difficult system to design as the hardware and
        simulation were very different. Initially the robot drove forward, with
        an enabled line following system, and then when either of the outer line
        sensors touched a line the line following system would turn off and the
        robot would drive 6cm forward (centering the robot over the junction).
        If the line sensors turned off in a specific pattern this information
        was sent to an if statement block where the output would be a junction
        type.
        {"\n\n"}This solution was later replaced with a more robust solution, as
        that system often had false line detections or the detections were not
        caught at all. The 4 line distance outputs were fed into a junction
        detecting chart. This chart initially enables the line following system,
        drives the robot forward at a constant velocity and if all the line
        sensors are off the line the junction type is returned as 1 (Dead end).
        If either of the outer line sensors tough a line the next state is
        entered. The moveForwardDist state moves the robot forward by 6cm and
        then, using the line sensor distance measurements, determines what
        junction was encountered. This is done by first turning the distances
        supplied into known states. If the line distance shows that it either
        was not on a line or is still on a line a value of 2 is supplied to the
        relative sensor variable. If the distance is above 1.8cm it means that
        the line sensor went over this line and a value of 1 is given to the
        relative variable.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Maze solver and shortest pathfinder algorithm</Heading>
      <Body>
        The maze learning algorithm was used for the initial maze completion.
        This algorithm utilizes the left hand method in order to solve the maze.
        This involves always taking the leftmost turn possible until the end of
        the maze is found. If a left turn is not possible then the algorithm
        will attempt to continue on straight. If this is not possible, a right
        turn will be taken. If all possibilities are found to be unavailable
        then the robot will turn around. Thus, the algorithm effectively has the
        robot “hug” the left side of the boundary lines of the maze until it
        completes the maze. This algorithm will eventually solve the maze as
        long as there are no closed loops within the maze. While the robot
        navigates the maze, the algorithm tracks all junctions encountered from
        start to finish. {"\n\n"}The shortest path algorithm simply takes the
        output array of the maze solver algorithm as an input. This array
        details the path taken by the robot from the start of the maze to the
        end of the maze using the left hand method. The shortest path algorithm
        then runs through this input array to flag 2 types of data points.
        “Decision Points” which are paths that can lead to permutations such as
        the Left T-Junction, Right T-Junction, T-Junction and the Cross Section.
        The other data point is the Dead End Junction. These were viewed in
        conjunction with one another as a dead end that represented an
        inefficient choice. {"\n\n"}It can then be further extrapolated that
        this comes as a result of the previous decision made which effectively
        acts as a “wrong choice”. This results in the robot having to backtrack
        back to the Decision Point to make a different choice. Thus, the branch
        of that choice can be removed by removing each element from that branch
        and replacing it with a Summation Point. This is the alternative choice
        that should have been made instead according to the Decision Point
        encountered when entering and leaving the unnecessary branch with the
        Dead End acting as the midpoint. This then replaces that branch with
        another track piece that would get the desired output. An extra junction
        was added, Straight Line which acts as a drive funcion as it is
        necessary for one permutation of the potential choices. This was when
        the robot needed to travel straight in presence of a Left T-Junction.
        Therefore, the shortest path is created by pruning the unnecessary paths
        and replacing the pruned branch with an alternative choice which would
        then give the same effective result.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Conclusion</Heading>
      <Body>
        The project design and implementation requirements of Motion control,
        Line sensing as well as a Maze Solver and Shortest Pathfinder algorithm
        were met. This enabled the robot to autonomously navigate and solve the
        maze as well as race it. Additionally, the robot was able to find the
        optimal path in order for the tobot to race the maze to completion in as
        little a time as possible.{"\n\n"}However, in the demo the robot was
        unable to complete the maze. While it was able to detect junctions and
        the end of the maze correctly, it was unable to finish the maze and
        would often have delayed reactions. This led to incorrect directions
        being taken by the robot. This resulted in the robot also not being able
        to race the maze as the mapping was incomplete as a result. {"\n\n"}
        This result could be due to a different robot being used. Thus, the
        calibrations could have been incorrect. Additionally, a different
        computer from testing was used. Thus, the computer performance could
        have been slower, resulting in the program's actions and analysis being
        delayed. Therefore, resulting in the robot being unable to complete the
        maze.
        <SubHeading>Links</SubHeading>•{" "}
        <Link
          onClick={() => {
            saveAs(
              process.env.PUBLIC_URL + "LineFollower_Report.pdf",
              "Kealym Bromley - LineFollower Report"
            );
          }}
        >
          Follower report
        </Link>
      </Body>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default LineFollower;
