import { useState, useCallback } from "react";
import { Grid, useMediaQuery, Divider, Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";
import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

import SolidWorksDemo from "./media/solidworks_demo.mp4";
import PhysicalDemo from "./media/physical_demo.mp4";

const TicTacToeBot = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The goal of this project was to build an interactive robot. At the
            time of conception Covid was still a large issue and so I decided to
            build a robot that could allow for socially distant TicTacToe. The
            concept would be that a plotter style robot could be placed over a
            piece of paper. The plotter robot will include a camera that will
            detect a TicTacToe grid and using image recognition could detect
            what symbols had been drawn on the grid. Using an app a remote
            player is then able to make their move, which then is drawn in by
            the plotter robot. The video {sm ? "to the right " : "below "} is a
            demonstration of the current state of the project.
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={PhysicalDemo} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Hardware Design</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            The first step at designing the socially distant TicTacToe bot was
            to mock up the plotter in Solidworks. I started with the main body,
            figuring out how the motors will move the X and Y axis smoothly,
            while not taking up too much space. I decided on a Y-axis arm that
            will hang over the X-axis, saving space at the expense of rigidity.
            For the smooth motion I settled on Vslot wheels which will roll on
            aluminum extrusion. I chose aluminum extrusion over smoother linear
            rails as cost was a limiting factor. Next I needed to decide how the
            pen or pencil will be lifted and placed on the piece of paper. I
            decided to use a small servo motor with a rack and pinon to move the
            pen up and down. This will allow for high pressure to be placed on
            the pen so that while drawing it will not lift. The designed motion
            of both axes as well as the rack and pinon is shown in the video{" "}
            {sm ? "to the right." : "below."}
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={SolidWorksDemo} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>Electronics</Heading>
      <Body>
        I am using a raspberry PI zero, with an attached webcam and the image
        classifier. This will be used to recognize what physical moves have been
        made and using a flask server will update the app with the drawn moves.
        Using the same flask server, when the remote user makes a move the
        Raspberry will communicate to an arduino running a G-code library to
        tell it where and what shape to draw. The project is currently waiting
        at this step as I have had to use the steppers for my thesis project.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>To be continued...</Heading>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default TicTacToeBot;
