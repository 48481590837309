import { Typography } from "@mui/material";

type BodyType = {
  children?: React.ReactNode;
};

const Body = (props: BodyType) => {
  return (
    <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
      {props.children}
    </Typography>
  );
};
export default Body;
