import { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  AppBar,
  Box,
  IconButton,
  Button,
  Container,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";

import FishFeeder from "./FishFeeder/FishFeeder";
import SignLanguage from "./SignLanguage/SignLanguage";
import PortraitDrawer from "./PortraitDrawer/PortraitDrawer";
import TicTacToeBot from "./TicTacToeBot/TicTacToeBot";

import Aquamesh from "./Aquamesh/Aquamesh";
import LineFollower from "./LineFollower/LineFollower";
import WarehouseRobot from "./WarehouseRobot/WarehouseRobot";
import PositionControl from "./PositionControl/PositionControl";

const ProjectViewer = () => {
  const locations = [
    "SignTutor",
    "FishFeeder",
    "PortraitDrawer",
    "TicTacToeBot",
    "WarehouseRobot",
    "LineFollower",
    "PositionControl",
    "Aquamesh",
  ];
  const titles = [
    "AI Powered ASL Tutor",
    "Automatic Fish Feeder",
    "Delta Portrait Drawer",
    "Remote Tic Tac Toe Bot",
    "Full State Feedback Warehouse Robot",
    "Line following maze solver",
    "Motor Position Control",
    "Aquamesh - smart water controller",
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const [project, setProject] = useState(0);
  const [title, setTitle] = useState("AI Sign Language Tutor");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.pathname.split("/").length === 3) {
      //gets path to set project being viewed
      const searchTerm = location.pathname.split("/")[2];
      const index = locations.findIndex((location) => {
        return searchTerm === location;
      });
      setProject(index);
      setTitle(titles[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const nextProject = () => {
    project < 7
      ? navigate("/projects/" + locations[project + 1])
      : navigate("/projects/" + locations[0]);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 2 }}
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBackIosIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1 }}
            align="center"
            margin={1}
          >
            {title}
          </Typography>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => nextProject()}
            size="large"
          >
            Next
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        {project === 0 && <SignLanguage />}
        {project === 1 && <FishFeeder />}
        {project === 2 && <PortraitDrawer />}
        {project === 3 && <TicTacToeBot />}
        {project === 4 && <WarehouseRobot />}
        {project === 5 && <LineFollower />}
        {project === 6 && <PositionControl />}
        {project === 7 && <Aquamesh />}
      </Container>
    </Box>
  );
};
export default ProjectViewer;
