import { useState, useCallback } from "react";
import { Link, Grid, useMediaQuery, Divider, Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

import SubHeading from "../Components/SubHeading";
import Image from "../Components/Image";

import MaskExample from "./media/mask_example.png";
import SignPromo from "./media/PROMO.mp4";
import TrainingResults from "./media/training_results.png";
import FigmaDesign from "./media/figma_design.png";

import Heading from "../Components/Heading";
import Video from "../Components/Video";
import Body from "../Components/Body";

const SignLanguage = () => {
  const sm = useMediaQuery("(min-width:900px)");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [MaskExample, TrainingResults, FigmaDesign];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container sx={{ paddingBottom: 5 }}>
      <Heading>The Objective</Heading>
      <Body>
        The goal of this project was to make learning sign language as
        accessible as learning a written language. I chose American Sign
        Language as it is one of the world's most commonly signed languages. To
        make the task easier I opted to only use static letters, but wanted to
        collect the training images myself. This project including the app,
        server, and AI, was all done on zero budget.
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>The Solution</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            To make sign language accessible I took inspiration from Duolingo,
            which teaches users written languages over an app. Using a mobile
            phone as the platform to teach sign languages has the advantage of
            having a back facing camera, this allows users to take a picture of
            their signed letter naturally, as well as a high performance
            processor, which in future will allow the classifier to be run
            locally. {`\n\n`}The next step was to decide on how the sign
            language will be taught. I decided to gameify the learning process
            and allow users to select between three difficulties. Where the
            hardest difficulty world require the user to sign the most amount of
            letters. The user then gets to save their score, which is dependant
            on word length and number of correct signs, which would be globally
            visible. {`\n\n`}
            Finally, I needed to decide how the app and classifier will
            communicate and to the detriment of speed, I chose to build an API
            that will host the classifier and communicate with the app. The tech
            stack I used is explained below.
          </Body>
        </Grid>
        <Grid item md={4}>
          <Video video={SignPromo} />
        </Grid>
      </Grid>
      <SubHeading>Tech stack</SubHeading>
      <Body>
        • TensorFlow - used to train, validate, and compress the Convolutional
        Neural Network classifier
        {`\n`}• Expo (React Native) - Used to build and host the app
        {`\n`}• Flask - Used to build the API
        {`\n`}• Firebase - Used for analytics
        {`\n`}• Azure - Hosts the API
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>The Implementation</Heading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            To create the solution described above, the first step was to
            collect the data used to train the classifier. To make sure the
            classifier would work for many skin tones and environments I decided
            to collect the data from 10 different people. To speed up this
            process I built a simple app that instructed users to centre their
            hand in a pose outlined on app interface. After taking 10 photos of
            each pose they then manually sent me those photos. The training
            images were first cropped and scaled, then, using a background
            removing algorithm, the hands were isolated, leaving only a black
            background. This batch background remover tool can be found at{" "}
            <Link
              href="https://github.com/KealymB/batchRemover"
              rel="noopener noreferrer"
              target="_blank"
            >
              this repository.
            </Link>{" "}
            An example of the output of the batch background remover can be seen
            in the figure {sm ? "to the right." : "below."}
          </Body>
        </Grid>
        <Image image={MaskExample} onPress={() => openImageViewer(0)} />
      </Grid>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            After extensive testing I decided to build the classifier using a
            Convolutional neural network in Tensorflow and Keras. This ConvNet
            was built to classify 5 static ASL letters. This AI was trained with
            500 600x600px images, with 20% of the data used for validation. To
            deal with the small dataset the signed letters went through data
            augmentation to artificially increase the training data by randomly
            rotating, scaling, and translating the images. During training
            dropout was used to help curb the over-fitting that occurs on small
            datasets. The CovNet was able to reach a 97% accuracy on the
            validation data and a 86% accuracy on the test data. The training
            results are shown in the figure {sm ? "to the right." : "below."}
          </Body>
        </Grid>
        <Image image={TrainingResults} onPress={() => openImageViewer(1)} />
      </Grid>
      <Body>
        The Google Colaboratory containing the code can be seen at{" "}
        <Link
          href="https://colab.research.google.com/drive/1qbhsZ-rFdZz6AC4Vp05SiN_dcjF7JxI8"
          rel="noopener noreferrer"
          target="_blank"
        >
          this link
        </Link>
        .
      </Body>
      <SubHeading>App</SubHeading>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item md={8}>
          <Body>
            I chose to use Expo and React Native to build the app, mainly
            because I have previous developer experience, but also because it
            offered a way of sharing the app on both Android and iOS without the
            need to buy a developer account to share it on the app store.
            {`\n\n`}
            The first step was do design the look and functionality of the app.
            As I had limited time there were only two screens designed out,
            which acted as guidelines while building out the app. I used
            Firebase analytics to track the usage of the app as well as Sentry
            to track any crashes or bugs. The app can downloaded using the Expo
            Go app and clicking{" "}
            <Link
              href="https://expo.dev/@kealym/signML"
              rel="noopener noreferrer"
              target="_blank"
            >
              this link
            </Link>
            .
          </Body>
        </Grid>
        <Image image={FigmaDesign} onPress={() => openImageViewer(2)} />
      </Grid>
      <SubHeading>API</SubHeading>
      <Body>
        For the API I chose to use Flask as it had the best documentation for a
        beginner python API creator. I then used the CPU version of TensorFlow
        as the free Azure server did not have the resources for the GPU. Making
        the API free was very challenging as the model prediction was an
        expensive task, but I managed to use google drive to store predicted
        images and github to host the model. The code for the API can be viewed
        at{" "}
        <Link
          href="https://github.com/KealymB/signTutorAPI"
          rel="noopener noreferrer"
          target="_blank"
        >
          this link
        </Link>
        .
      </Body>
      <Divider sx={{ marginTop: 2 }} />
      <Heading>The Conclusion</Heading>
      <Body>
        Overall I believe the project was a success, meeting the goal of making
        learning sign language as accessible as learning a written language.
        There were however several drawbacks, or things that I would would
        improve if I got the chance.
      </Body>
      <SubHeading>Improvements</SubHeading>
      <Body>
        • After getting several different users to try out the app, there is a
        clear lack of understanding when it comes to the gamification section of
        the app. This is something I would love to address if I got to work on
        the project further.{"\n"}• The next issue is an issue of prediction
        speed. Currently the app first uploads the image to the API, where the
        API downloads the image and then runs the prediction step on the CPU. I
        would have rather run the prediction step locally, this could be done
        using TensorFlow Lite, but time constraints got in the way.{"\n"}•
        Finally I would love to include gestured signs as well, this will
        drastically improve the viability of such a teaching tool.
      </Body>
      <SubHeading>Links</SubHeading>
      <Body>
        •{" "}
        <Link
          href="https://github.com/KealymB/signtutorAPP"
          rel="noopener noreferrer"
          target="_blank"
        >
          App repository
        </Link>
        {"\n"}•{" "}
        <Link
          href="https://github.com/KealymB/signTutorAPI"
          rel="noopener noreferrer"
          target="_blank"
        >
          API repository
        </Link>
        {"\n"}•{" "}
        <Link
          href="https://colab.research.google.com/drive/1qbhsZ-rFdZz6AC4Vp05SiN_dcjF7JxI8"
          rel="noopener noreferrer"
          target="_blank"
        >
          ASL ConvNet classifier Google Colab
        </Link>
        {"\n"}•{" "}
        <Link
          href="https://github.com/KealymB/batchRemover"
          rel="noopener noreferrer"
          target="_blank"
        >
          Batch Image Background remover Repository
        </Link>
      </Body>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
};
export default SignLanguage;
