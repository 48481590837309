import { Grid, Button } from "@mui/material";

type ContactButtonTypes = {
  onPress: () => void;
  text: string;
};

const ContactButton = (props: ContactButtonTypes) => {
  return (
    <Grid container alignItems="center" justifyContent="center" mt={2}>
      <Button
        onClick={() => {
          props.onPress();
        }}
        variant="contained"
        color="secondary"
        size="large"
        sx={{ boxShadow: 3 }}
      >
        {props.text}
      </Button>
    </Grid>
  );
};

export default ContactButton;
