import { Typography, Button, Grid } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import GitHubIcon from "@mui/icons-material/GitHub";
import event from "../../utils/Analytics";

type ListItemType = {
  icon: string;
  label: string;
  onPress: () => void;
};

const ListItem = (props: ListItemType) => {
  return (
    <Grid container spacing={0} direction="row" justifyContent="center" mb={3}>
      <Button
        onClick={() => {
          props.onPress();
          event(props.icon);
        }}
        style={{ textTransform: "none" }}
      >
        {props.icon === "linkedIn" && (
          <LinkedInIcon sx={{ fontSize: 40, marginRight: 2 }} />
        )}
        {props.icon === "email" && (
          <EmailIcon sx={{ fontSize: 40, marginRight: 2 }} />
        )}
        {props.icon === "download" && (
          <DownloadIcon sx={{ fontSize: 40, marginRight: 2 }} />
        )}
        {props.icon === "phone" && (
          <LocalPhoneIcon sx={{ fontSize: 40, marginRight: 2 }} />
        )}
        {props.icon === "github" && (
          <GitHubIcon sx={{ fontSize: 40, marginRight: 2 }} />
        )}

        <Typography variant="h4" align={"center"}>
          {props.label}
        </Typography>
      </Button>
    </Grid>
  );
};

export default ListItem;
