import ReactPlayer from "react-player";

type VideoType = {
  video: string;
};

const Video = (props: VideoType) => {
  return (
    <ReactPlayer
      url={props.video}
      height={200}
      width={"100%"}
      playing
      muted
      controls
    />
  );
};

export default Video;
